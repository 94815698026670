import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, CircularProgress } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import ToastWrapper from './ToastWrapper';
import { useToastMessage } from '../hooks/useToastMessage';
import { ToastType } from '../interfaces';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { loadOrganizations, selectOrganizations } from '../store/organizations.reducer';
import { attachOnboardDeviceOrganization } from '../api/attachOnboardDeviceOrganization';

interface Props {
    open: boolean;
    selectedDeviceIds: string[];
    onAttachComplete: () => void;
    onClose: () => void;
}
export const AttachOrganizationDialog = ({ open, onClose, selectedDeviceIds, onAttachComplete }: Props) => {
    const dispatch = useAppDispatch();
    const organizations = useAppSelector(selectOrganizations);

    useEffect(() => {
        dispatch(loadOrganizations());
    }, []);

    const [selectedOrganizationId, setSelectedOrganizationId] = useState<string | null>();
    const [loading, setLoading] = useState(false);
    const { isOpened, toastMessage, setToastMessage, handleCloseToast } = useToastMessage();

    const updateVersions = async () => {
        try {
            setLoading(true);
            await Promise.all(
                selectedDeviceIds.map((deviceId) => {
                    return attachOnboardDeviceOrganization(deviceId, selectedOrganizationId!);
                }),
            );

            onAttachComplete();
        } catch (e) {
            console.error(e);
            setToastMessage({
                message: `При додаванні до організації відбулася помилка`,
                type: ToastType.WARNING,
            });
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <Dialog open={open} onClose={onClose}>
                <DialogTitle>Attach Organization</DialogTitle>
                <DialogContent sx={{ width: 350 }}>
                    {!organizations ? (
                        <div style={{ display: 'flex', justifyContent: 'center', padding: '25px 0' }}>
                            <CircularProgress />
                        </div>
                    ) : (
                        <>
                            <Box sx={{ marginTop: 1, marginBottom: 1, minHeight: 70 }}>
                                <Autocomplete
                                    sx={{ marginTop: 1 }}
                                    onChange={(e, newValue) => {
                                        setSelectedOrganizationId(newValue?.id);
                                    }}
                                    fullWidth
                                    size={'small'}
                                    renderInput={(params) => <TextField {...params} label="Organization" />}
                                    options={organizations}
                                    getOptionLabel={(option) => option.name}
                                    isOptionEqualToValue={(option, value) => option.id === value?.id}
                                />
                            </Box>
                        </>
                    )}

                    <DialogActions>
                        <Button size={'small'} variant="outlined" onClick={onClose} color="error">
                            Cancel
                        </Button>
                        <Button
                            disabled={loading || !selectedOrganizationId}
                            size={'small'}
                            variant="outlined"
                            onClick={updateVersions}
                            color="primary"
                        >
                            Save
                            {loading && (
                                <span className="ml-2 inline-flex">
                                    <CircularProgress size={15} />
                                </span>
                            )}
                        </Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>
            <ToastWrapper
                open={isOpened}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                autoHideDuration={3000}
                onClose={handleCloseToast}
                message={toastMessage?.message}
                type={toastMessage?.type}
            />
        </>
    );
};
