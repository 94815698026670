import { Point as LeafletPoint } from 'leaflet';
import { useMemo } from 'react';
import styled from 'styled-components';

export interface HoverInfo {
    point: LeafletPoint;
    isAvailable: boolean;
}

const TooltipBody = styled.div`
    display: flex;
    z-index: 1000;
    position: absolute;
    background-color: rgba(97, 97, 97, 0.92);
    border-radius: 4px;
    color: #fff;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    padding: 4px 8px;
    font-size: 0.6875rem;
    max-width: 300px;
    margin: 2px;
    word-wrap: break-word;
    font-weight: 500;
`;

interface MapTooltipProps {
    hoverInfo: HoverInfo | null;
}

export const MapTooltip = ({ hoverInfo }: MapTooltipProps) => {
    const tooltipText = useMemo(() => {
        if (hoverInfo) {
            const availability = hoverInfo.isAvailable ? `Натисніть щоб додати зону.` : 'Не доступно';
            return availability;
        }

        return '';
    }, [hoverInfo]);

    const tooltipStyle = useMemo(() => {
        if (hoverInfo) {
            return {
                display: 'flex',
                left: hoverInfo.point.x - (hoverInfo.isAvailable ? 80 : 50),
                top: hoverInfo.point.y - 40,
            };
        }
        return {
            display: 'none',
        };
    }, [hoverInfo]);

    return (
        <TooltipBody className="tooltip" style={tooltipStyle}>
            {tooltipText}
        </TooltipBody>
    );
};
