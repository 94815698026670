import * as React from 'react';
import { useMemo, useState } from 'react';
import { Button, CircularProgress, IconButton, List, ListItem, ListItemButton } from '@mui/material';
import { ShapeHistoryItem, ToastType, ZoneListItem } from '../interfaces';
import { createZone } from '../api/createZone';
import { area as TurfArea } from '@turf/turf';
import TextField from '@mui/material/TextField';
import { useToastMessage } from '../hooks/useToastMessage';
import ToastWrapper from './ToastWrapper';
import ZoomInMapIcon from '@mui/icons-material/ZoomInMap';
import { Formik, Form, Field } from 'formik';
import { object, string, addMethod } from 'yup';
import { useAppSelector } from '../store/hooks';
import { selectZones } from '../store/maps.reducer';
import Box from '@mui/material/Box';
import { NominatimGeoJsonSearch } from './NominatimGeoJsonSearch';
import { LineString, MultiPolygon, Point, Polygon } from 'geojson';
import { navigateToTilesViewer } from './SourceTilesViewer/navigateToTilesViewer';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { validateGeoJson } from '../utils/validateGeoJson';
import ClearIcon from '@mui/icons-material/Clear';
import { mergeGeoJsonShapesIntoMultiPolygon } from '../utils/mergeGeoJsonShapesIntoMultiPolygon';
import { geoJsonPolygonToTurf } from '../utils/geoJsonPolygonToTurf';
import { isInnerPolygon } from '../utils/isInnerPolygon';
import { useGetAvailableAreaPolygon } from '../hooks/useGetAvailableAreaPolygon';

declare module 'yup' {
    interface StringSchema {
        uniqueName(zones: ZoneListItem[], message: string): this;
    }
}

addMethod(string, 'uniqueName', function (zones: ZoneListItem[], message) {
    return this.test('uniqueName', message, function (value) {
        const { path, createError } = this;
        const isUnique = zones.every(
            (zone: ZoneListItem) => zone.name.toLowerCase() !== value?.toLowerCase(),
        );
        return isUnique || createError({ path, message });
    });
});

interface NewZoneProps {
    selectedGeoJsonShape: Polygon | MultiPolygon | Point | LineString | null;
    addedShapesHistory: ShapeHistoryItem[];
    onShapesHistoryClear: (index: number) => void;
    onShapeClear: () => void;
    onShapeAdd: (selectedGeoJsonShape: Polygon | MultiPolygon) => void;
    onShapeChange: (value: { name: string; geoJsonShape: Polygon | MultiPolygon }) => void;
    onZoneCreated: () => void;
}

export const NewZone = ({
    selectedGeoJsonShape,
    onShapeClear,
    addedShapesHistory,
    onShapeAdd,
    onZoneCreated,
    onShapeChange,
    onShapesHistoryClear,
}: NewZoneProps) => {
    const [loading, setLoading] = useState<boolean>(false);
    const { isOpened, toastMessage, setToastMessage, handleCloseToast } = useToastMessage();
    const zones = useAppSelector(selectZones);
    const polygonFeature = useGetAvailableAreaPolygon();

    const validationSchema = object({
        name: string()
            .required('Назва зони є обов’язковою')
            .uniqueName(zones!, 'Назва зони повинна бути унікальною'),
    });

    const selectedShapesUnion = useMemo(() => {
        if (!(selectedGeoJsonShape || addedShapesHistory.length > 0)) {
            return null;
        }

        if (selectedGeoJsonShape && !validateGeoJson(selectedGeoJsonShape)) {
            return null;
        }

        let shape: Polygon | MultiPolygon;
        const shapes: Array<Polygon | MultiPolygon> = addedShapesHistory.map((value) => value.geoJson);
        if (selectedGeoJsonShape) {
            shapes.push(selectedGeoJsonShape as Polygon | MultiPolygon);
        }

        if (shapes.length === 1) {
            shape = shapes[0];
        } else if (shapes.length > 1) {
            shape = mergeGeoJsonShapesIntoMultiPolygon(shapes);
        } else {
            return null;
        }

        return shape;
    }, [selectedGeoJsonShape, addedShapesHistory]);

    const shapeArea = useMemo(() => {
        if (!selectedShapesUnion) return 0;

        const turfPolygon = geoJsonPolygonToTurf(selectedShapesUnion);

        if (turfPolygon) {
            const calculatedArea = TurfArea(turfPolygon);
            return Math.round(calculatedArea / 1000000); // Convert to square kilometers
        }

        return 0; // Return 0 if the shape type is unsupported
    }, [selectedShapesUnion]);

    const triggerCreateZone = async (values: { name: string }) => {
        if (selectedShapesUnion) {
            setLoading(true);
            try {
                await createZone({
                    name: values.name,
                    geoJson: selectedShapesUnion,
                    area: shapeArea,
                });
                onZoneCreated();
            } catch (e) {
                console.error('Create zone failed', e);
                setToastMessage({
                    message: `При створенні зони відбулася помилка`,
                    type: ToastType.WARNING,
                });
            } finally {
                setLoading(false);
            }
        }
    };

    const isValidGeometry = useMemo(() => {
        if (!selectedShapesUnion || !polygonFeature) {
            return false;
        }

        const innerPolygon = geoJsonPolygonToTurf(selectedShapesUnion);

        if (!innerPolygon) {
            return false;
        }

        return isInnerPolygon(innerPolygon, polygonFeature);
    }, [selectedGeoJsonShape, selectedShapesUnion, polygonFeature]);

    return (
        <>
            <Formik
                initialValues={{ name: '' }}
                validationSchema={validationSchema}
                onSubmit={triggerCreateZone}
            >
                {({ touched, errors, isValid }) => (
                    <Form className="flex-grow relative pt-2">
                        {addedShapesHistory.length > 0 && (
                            <Box className="mb-2">
                                <Typography variant={'h6'}>Додані Полігони</Typography>
                                <List className="mt-2">
                                    {addedShapesHistory.map((value, index) => {
                                        return (
                                            <ListItem
                                                key={index}
                                                disablePadding
                                                secondaryAction={
                                                    <IconButton
                                                        edge="end" // Aligns the icon to the end of the ListItem
                                                        onClick={() => {
                                                            onShapesHistoryClear(index); // Handle remove action here
                                                        }}
                                                    >
                                                        <ClearIcon />
                                                    </IconButton>
                                                }
                                            >
                                                <ListItemButton>
                                                    <Typography>{value.name}</Typography>
                                                </ListItemButton>
                                            </ListItem>
                                        );
                                    })}
                                </List>
                            </Box>
                        )}

                        <Typography variant={'h6'}>Новий Полігон</Typography>
                        <Box className="mb-2 mt-2">
                            <NominatimGeoJsonSearch
                                onChange={(value) => {
                                    onShapeChange({
                                        name: value.searchQuery,
                                        geoJsonShape: value.geoJson,
                                    });
                                }}
                            />
                        </Box>
                        <Box className="mb-2">
                            <Button disabled={loading} fullWidth onClick={onShapeClear} variant="outlined">
                                Очистити зону
                            </Button>
                        </Box>
                        <Box className="mb-4">
                            <Button
                                onClick={() => {
                                    onShapeAdd(selectedGeoJsonShape as Polygon | MultiPolygon);
                                }}
                                disabled={!selectedGeoJsonShape || !isValidGeometry}
                                fullWidth
                                variant="outlined"
                            >
                                Додати полігон
                            </Button>
                        </Box>
                        <Divider />
                        <Box className={'mt-4'}>
                            <Field
                                as={TextField}
                                name="name"
                                size="small"
                                fullWidth
                                label="Назва нової зони"
                                variant="outlined"
                                error={touched.name && !!errors.name}
                                helperText={touched.name && errors.name}
                            />
                        </Box>

                        <Box className="mt-2">
                            <Button
                                fullWidth
                                startIcon={<ZoomInMapIcon />}
                                disabled={
                                    loading ||
                                    !isValidGeometry ||
                                    !selectedShapesUnion ||
                                    !shapeArea ||
                                    shapeArea > 100000
                                }
                                onClick={() => {
                                    navigateToTilesViewer({
                                        geoJson: selectedShapesUnion!,
                                    });
                                }}
                                variant="outlined"
                            >
                                {`Сорси (${shapeArea} км²)`}
                            </Button>
                        </Box>
                        <Box className="mt-2">
                            <Button
                                type="submit"
                                fullWidth
                                disabled={!isValid || loading || !isValidGeometry}
                                variant="outlined"
                            >
                                Зберегти зону
                                {loading && (
                                    <span className="ml-2 inline-flex">
                                        <CircularProgress size={15} />
                                    </span>
                                )}
                            </Button>
                        </Box>
                    </Form>
                )}
            </Formik>
            <ToastWrapper
                open={isOpened}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                autoHideDuration={3000}
                onClose={handleCloseToast}
                message={toastMessage?.message}
                type={toastMessage?.type}
            />
        </>
    );
};
