import React from 'react';
import { Form, Formik } from 'formik';
import { Radio, RadioGroup, FormControlLabel, FormControl, Typography } from '@mui/material';

interface SourcePickerProps {
    sources: string[];
    initialSource: string;
    onSubmit: (selectedSource: string) => void;
}

const SourcePicker: React.FC<SourcePickerProps> = ({ sources, initialSource, onSubmit }) => {
    return (
        <Formik
            initialValues={{ selectedSource: initialSource }}
            onSubmit={(values) => onSubmit(values.selectedSource)}
        >
            {({ setFieldValue, values }) => (
                <Form>
                    <FormControl component="fieldset">
                        <Typography variant={'h6'}>Sources</Typography>
                        <RadioGroup
                            aria-label="source"
                            name="selectedSource"
                            value={values.selectedSource}
                            onChange={(e) => {
                                setFieldValue('selectedSource', e.target.value);
                                onSubmit(e.target.value);
                            }}
                        >
                            <FormControlLabel value="All" control={<Radio />} label="All" />
                            {sources.map((source) => (
                                <FormControlLabel
                                    key={source}
                                    value={source}
                                    control={<Radio />}
                                    label={source}
                                />
                            ))}
                        </RadioGroup>
                    </FormControl>
                </Form>
            )}
        </Formik>
    );
};

export default SourcePicker;
