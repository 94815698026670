import { GridCellParams } from '@mui/x-data-grid';
import styled from 'styled-components';
import { CopyButton } from '../CopyButton';

export const LongTextCellWrapper = styled.div`
    position: relative;
    padding-right: 40px;
    display: flex;

    & .copy-btn {
        display: none;
    }

    &:hover .copy-btn {
        display: block;
    }
`;

export const CopyTextCell = (cellValues: GridCellParams) => {
    return (
        <LongTextCellWrapper>
            {cellValues.value ? (
                <>
                    <span
                        style={{
                            fontFamily: 'ui-monospace, monospace',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                        }}
                    >
                        {cellValues.value as string}
                    </span>

                    <span className="copy-btn">
                        <CopyButton value={cellValues.value as string} />
                    </span>
                </>
            ) : (
                <></>
            )}
        </LongTextCellWrapper>
    );
};
