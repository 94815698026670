import { useField, useFormikContext } from 'formik';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

interface FormikAutocompleteProps {
    name: string;
    options: any[];
    label: string;
    onChange?: (event: any, value: any) => void;
}

export const FormikAutocomplete = ({
    name,
    options,
    label,
    onChange,
    ...otherProps
}: FormikAutocompleteProps) => {
    const { setFieldValue } = useFormikContext();
    const [field, meta] = useField(name);

    const handleChange = (event: any, value: any) => {
        setFieldValue(name, value);
        if (onChange) {
            onChange(event, value);
        }
    };

    return (
        <Autocomplete
            {...field}
            {...otherProps}
            options={options}
            onChange={handleChange}
            value={field.value || null}
            renderInput={(params) => (
                <TextField
                    {...params}
                    error={meta.touched && Boolean(meta.error)}
                    helperText={meta.touched && meta.error}
                    label={label}
                />
            )}
        />
    );
};
