import React, { useEffect, useMemo, useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    CircularProgress,
    FormControl,
} from '@mui/material';
import ToastWrapper from './ToastWrapper';
import { useToastMessage } from '../hooks/useToastMessage';
import { Device, Organization, OrganizationForm, SystemConfiguration, ToastType } from '../interfaces';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { createOnboardDevice } from '../api/createOnboardDevice';
import { LinearProgressWithLabel } from './LinearProgressWithLabel';
import { loadSystemConfigurations, selectSystemConfigurations } from '../store/devices.reducer';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { loadOrganizations, selectOrganizations } from '../store/organizations.reducer';
import { Field, Form, Formik } from 'formik';
import { FormikAutocomplete } from './FormikAutocomplete';
import { objectIdToString } from '../utils/objectIdToString';

interface Props {
    open: boolean;
    onCreationCompleted: () => void;
    onClose: () => void;
}

function isInteger(str: string) {
    const regex = /^-?\d+$/;
    return regex.test(str);
}

export const CreateDevicesDialog = ({ open, onClose, onCreationCompleted }: Props) => {
    const dispatch = useAppDispatch();
    const [devicesCount, setDevicesCount] = useState<string>('');
    const [progress, setProgress] = useState<number>(0);
    const [loading, setLoading] = useState(false);
    const { isOpened, toastMessage, setToastMessage, handleCloseToast } = useToastMessage();

    const availableConfigurations = useAppSelector(selectSystemConfigurations);
    const availableOrganizations = useAppSelector(selectOrganizations);

    const [selectedOrganization, setSelectedOrganization] = useState<OrganizationForm | null>(null);

    useEffect(() => {
        dispatch(loadOrganizations());
        dispatch(loadSystemConfigurations());
    }, []);

    const filteredConfigurations = useMemo(() => {
        if (selectedOrganization && availableConfigurations) {
            const res = availableConfigurations.filter(
                (config) =>
                    objectIdToString(config._id) ===
                    objectIdToString(selectedOrganization.oscarConfiguration?._id),
            );

            //If organization don't have selected configuration, all configuration are available
            if (res.length === 0) return availableConfigurations;

            return res;
        }
        return availableConfigurations;
    }, [selectedOrganization, availableConfigurations]);

    const isOverMaximumAllowed = useMemo(() => {
        return parseInt(devicesCount) > 100;
    }, [devicesCount]);

    const handleSubmit = async (values: Device, { setSubmitting }: any) => {
        try {
            setLoading(true);
            const counter = parseInt(devicesCount);
            for (let i = 1; i <= counter; i++) {
                try {
                    const organizationId = values.organizationDetails?.id ?? undefined;
                    const oscarConfigurationId = values.configurationDetails?._id
                        ? objectIdToString(values.configurationDetails?._id)
                        : undefined;
                    await createOnboardDevice({ organizationId, oscarConfigurationId });
                } catch (e) {
                    setToastMessage({
                        message: `При створенні девайсу ${i} відбулася помилка`,
                        type: ToastType.WARNING,
                    });
                } finally {
                    setProgress(i / counter);
                }
            }
            setLoading(false);
            onCreationCompleted();
            setProgress(0);
        } catch (error) {
            setToastMessage({
                message: `Під час створення девайсів відбулася помилка`,
                type: ToastType.WARNING,
            });
        } finally {
            setSubmitting(false);
        }
    };

    const isConfigurationOptionEqualToValue = (
        option: SystemConfiguration,
        value: SystemConfiguration | null,
    ) => {
        return option.systemVersion === value?.systemVersion;
    };

    const isOrganizationOptionEqualToValue = (option: Organization, value: Organization | null) => {
        return option.id === value?.id;
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Create devices</DialogTitle>
            <DialogContent sx={{ width: 350 }}>
                <Formik onSubmit={handleSubmit} initialValues={{}}>
                    {({ isSubmitting }) => (
                        <Form>
                            <Box sx={{ marginTop: 1, marginBottom: 2 }}>
                                <TextField
                                    label="Enter count"
                                    error={isOverMaximumAllowed}
                                    helperText={
                                        isOverMaximumAllowed
                                            ? 'Максимум дозволено 100 шт за одну операцію'
                                            : ''
                                    }
                                    size={'small'}
                                    fullWidth={true}
                                    value={devicesCount}
                                    inputProps={{ type: 'number' }}
                                    onChange={(event) => {
                                        if (!event.target.value) {
                                            setDevicesCount('');
                                        }

                                        if (!isInteger(event.target.value)) {
                                            event.preventDefault();
                                            return;
                                        }

                                        setDevicesCount(parseInt(event.target.value).toString());
                                    }}
                                />
                            </Box>

                            {availableOrganizations && (
                                <FormControl fullWidth sx={{ marginBottom: 2 }}>
                                    <Field
                                        as={FormikAutocomplete}
                                        size="small"
                                        name="organizationDetails"
                                        label="Organization"
                                        onChange={(e: any, val: Organization | null) => {
                                            setSelectedOrganization(val);
                                        }}
                                        isOptionEqualToValue={isOrganizationOptionEqualToValue}
                                        options={availableOrganizations}
                                        getOptionLabel={(option: Organization) => option.name || ''}
                                    />
                                </FormControl>
                            )}

                            {filteredConfigurations && (
                                <FormControl fullWidth sx={{ marginBottom: 2 }}>
                                    <Field
                                        as={FormikAutocomplete}
                                        size="small"
                                        name="configurationDetails"
                                        label="Configuration"
                                        isOptionEqualToValue={isConfigurationOptionEqualToValue}
                                        options={filteredConfigurations}
                                        getOptionLabel={(option: SystemConfiguration) =>
                                            option.systemVersion || ''
                                        }
                                    />
                                </FormControl>
                            )}

                            {loading && (
                                <Box sx={{ width: '100%', marginBottom: 1 }}>
                                    <LinearProgressWithLabel value={progress * 100} />
                                </Box>
                            )}

                            <DialogActions>
                                <Button
                                    disabled={loading}
                                    size={'small'}
                                    variant="outlined"
                                    onClick={onClose}
                                    color="error"
                                >
                                    Cancel
                                </Button>
                                <Button
                                    type="submit"
                                    disabled={
                                        loading || !devicesCount || isOverMaximumAllowed || isSubmitting
                                    }
                                    size={'small'}
                                    variant="outlined"
                                    color="primary"
                                >
                                    Create
                                    {loading && (
                                        <span className="ml-2 inline-flex">
                                            <CircularProgress size={15} />
                                        </span>
                                    )}
                                </Button>
                            </DialogActions>
                        </Form>
                    )}
                </Formik>
            </DialogContent>
            <ToastWrapper
                open={isOpened}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                autoHideDuration={3000}
                onClose={handleCloseToast}
                message={toastMessage?.message}
                type={toastMessage?.type}
            />
        </Dialog>
    );
};
