import { GridCellParams } from '@mui/x-data-grid';
import React, { useMemo } from 'react';
import { CopyButton } from '../CopyButton';
import { LongTextCellWrapper } from './CopyTextCell';
import { SystemConfiguration } from '../../interfaces';

export const ConfigurationVersionLabelCell = (cellValues: GridCellParams) => {
    const configuration = useMemo(() => {
        const configuration = cellValues.value as SystemConfiguration;
        return configuration.systemVersion || '';
    }, [cellValues]);

    return (
        <LongTextCellWrapper>
            {cellValues.value ? (
                <>
                    <span
                        style={{
                            fontFamily: 'ui-monospace, monospace',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                        }}
                    >
                        {configuration}
                    </span>

                    <span className="copy-btn">
                        <CopyButton value={cellValues.value as string} />
                    </span>
                </>
            ) : (
                <></>
            )}
        </LongTextCellWrapper>
    );
};
