import React, { useMemo, useState } from 'react';
import {
    DataGrid,
    GridColDef,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridCellParams,
} from '@mui/x-data-grid';
import { Organization, OrganizationForm } from '../interfaces';
import { transformKeyToTitle } from '../utils/transformKeyToTitle';
import Button from '@mui/material/Button';
import { Add as AddIcon } from '@mui/icons-material';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useAppDispatch } from '../store/hooks';
import { loadOrganizations } from '../store/organizations.reducer';
import { UpsertOrganizationDialog } from './UpsertOrganizationDialog';
import { useManageGrid } from '../hooks/useManageGrid';
import { OrganizationLabelCell } from './GridCells/OrganizationLabelCell';
import { OrganizationActionsLabelCell } from './GridCells/OrganizationActionsLabelCell';
import { ConfigurationVersionLabelCell } from './GridCells/ConfigurationVersionLabelCell';

interface DataGridComponentProps {
    organizations: Organization[];
}

const defaultOrganization: OrganizationForm = {
    id: null,
    name: '',
    labelColor: '',
    oscarConfiguration: null,
};

interface ToolbarProps {
    setIsOrganizationDialogOpen: (value: boolean) => void;
    setSelectedOrganization: (value: React.SetStateAction<OrganizationForm>) => void;
}

function Toolbar({ setIsOrganizationDialogOpen, setSelectedOrganization }: ToolbarProps) {
    const dispatch = useAppDispatch();

    const handleAddClick = () => {
        setSelectedOrganization(defaultOrganization);
        setIsOrganizationDialogOpen(true);
    };

    const onReload = () => {
        dispatch(loadOrganizations());
    };

    return (
        <GridToolbarContainer>
            <GridToolbarColumnsButton />
            <GridToolbarFilterButton />
            <Button startIcon={<RefreshIcon />} color="primary" onClick={onReload}>
                Reload
            </Button>
            <Button startIcon={<AddIcon />} color="primary" onClick={handleAddClick}>
                Add
            </Button>
        </GridToolbarContainer>
    );
}

export const OrganizationsTableGrid = ({ organizations }: DataGridComponentProps) => {
    const [upsertOrganizationDialogOpen, setUpsertOrganizationDialogOpen] = useState(false);
    const [initialValues, setInitialValues] = useState(defaultOrganization);

    const {
        sortModel,
        columnsConfig,
        columnsWidth,
        gridFilterModel,
        handleColumnVisibilityChange,
        handleColumnWidthChange,
        handleSortModelChange,
        handleFilterModelChange,
        onCellKeyDown,
        gridStateSynced,
    } = useManageGrid('organizations');

    const columns: GridColDef[] = useMemo(() => {
        return [
            { key: 'name', renderCell: OrganizationLabelCell },
            {
                key: 'oscarConfiguration',
                renderCell: ConfigurationVersionLabelCell,
            },
            {
                key: 'actions',
                renderCell: (cellValues: GridCellParams) => (
                    <OrganizationActionsLabelCell
                        {...cellValues}
                        setIsOrganizationDialogOpen={setUpsertOrganizationDialogOpen}
                        setSelectedOrganization={setInitialValues}
                    />
                ),
                width: 200,
            },
        ].map((col) => {
            return {
                field: col.key,
                headerName: transformKeyToTitle(col.key),
                renderCell: col.renderCell,
                width: columnsWidth[col.key] || col.width || 250,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
            };
        });
    }, [columnsWidth]);

    return (
        <>
            {gridStateSynced && (
                <DataGrid
                    rows={organizations}
                    columns={columns}
                    slots={{
                        toolbar: (props) => (
                            <Toolbar
                                {...props}
                                setIsOrganizationDialogOpen={setUpsertOrganizationDialogOpen}
                                setSelectedOrganization={setInitialValues}
                            />
                        ),
                    }}
                    disableRowSelectionOnClick
                    sortModel={sortModel}
                    filterModel={gridFilterModel}
                    onSortModelChange={handleSortModelChange}
                    onColumnWidthChange={handleColumnWidthChange}
                    onColumnVisibilityModelChange={handleColumnVisibilityChange}
                    onFilterModelChange={handleFilterModelChange}
                    columnVisibilityModel={columnsConfig}
                    onCellKeyDown={onCellKeyDown}
                />
            )}

            {upsertOrganizationDialogOpen && (
                <UpsertOrganizationDialog
                    open={upsertOrganizationDialogOpen}
                    onClose={() => setUpsertOrganizationDialogOpen(false)}
                    initialValues={initialValues}
                />
            )}
        </>
    );
};
