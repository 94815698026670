import L, { LatLngExpression, LatLngLiteral, LeafletMouseEvent, Map, Marker } from 'leaflet';
import { useEffect, useRef, useState } from 'react';
import { useGetCurrentPosition } from '../hooks/useGetCurrentPosition';
import { booleanPointInPolygon, point } from '@turf/turf';
import { throttle } from 'underscore';
import { useGetAvailableAreaPolygon } from '../hooks/useGetAvailableAreaPolygon';
import Box from '@mui/material/Box';
import { defaultMapCenter } from '../config';
import { Polygon, MultiPolygon, Point, LineString, Geometry, Feature, GeoJsonProperties } from 'geojson';
import { getGeoJsonPolygonBounds } from '../utils/getGeoJsonPolygonBounds';
import { ShapeHistoryItem } from '../interfaces';
import { HoverInfo, MapTooltip } from './MapTooltip';
import MapInfo from './MapInfo';

const stylelayer = {
    defecto: {
        color: '#778d31',
        opacity: 1,
        fillcolor: '#778d31',
        fillOpacity: 0.1,
        weight: 0.5,
        interactive: true,
    },
    highlight: {
        color: '#778d31',
        opacity: 1,
        fillOpacity: 0.45,
        weight: 1,
    },
};

interface MapPickerProps {
    selectedGeoJsonShape: Polygon | MultiPolygon | Point | LineString | null;
    addedShapesHistory: ShapeHistoryItem[];
    manualPolygonCoordinates: number[][];
    onManualPolygonChange: (LnglatPoint: number[]) => void;
    availableSources: Record<string, Feature<MultiPolygon, GeoJsonProperties>> | null;
    selectedSource: string;
}

export const MapPicker = ({
    selectedGeoJsonShape,
    onManualPolygonChange,
    addedShapesHistory,
    manualPolygonCoordinates,
    availableSources,
    selectedSource,
}: MapPickerProps) => {
    const [hoverInfo, setHoverInfo] = useState<HoverInfo | null>(null);
    const mapRef = useRef<HTMLDivElement>(null);
    const [map, setMap] = useState<Map>();
    const { currentPosition } = useGetCurrentPosition();
    const polygonFeature = useGetAvailableAreaPolygon();
    const hoveredSourceRef = useRef<string>('');

    useEffect(() => {
        if (
            map &&
            selectedGeoJsonShape &&
            (selectedGeoJsonShape.type === 'Polygon' || selectedGeoJsonShape.type === 'MultiPolygon') &&
            manualPolygonCoordinates.length === 0
        ) {
            const bounds = getGeoJsonPolygonBounds(selectedGeoJsonShape);

            map.fitBounds(bounds);
        }
    }, [selectedGeoJsonShape, map, manualPolygonCoordinates]);

    useEffect(() => {
        const map = L.map(mapRef.current!);
        L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
            subdomains: 'abcd',
            attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
            minZoom: 3,
            maxZoom: 18,
        }).addTo(map);
        map.setZoom(7);
        map.attributionControl.setPosition('bottomleft');
        map.zoomControl.setPosition('bottomright');
        setMap(map);

        return () => {
            map?.remove();
        };
    }, []);

    useEffect(() => {
        if (map && availableSources) {
            clearMapLayers();
            Object.keys(availableSources).forEach((source) => {
                if (selectedSource === source || selectedSource == 'All') {
                    L.geoJSON(availableSources[source], {
                        style: stylelayer.defecto,
                        onEachFeature: onEachFeature,
                    }).addTo(map);
                }
            });
        }
    }, [map, availableSources, selectedSource]);

    const onEachFeature = (_feature: Feature<Geometry, any>, layer: L.Layer) => {
        const onMouseOver = throttle(() => {
            if (layer instanceof L.Polygon || layer instanceof L.Polyline) {
                layer.setStyle(stylelayer.highlight);
                hoveredSourceRef.current = layer.feature?.properties.name;
            }
        }, 5);

        const onMouseOut = throttle(() => {
            if (layer instanceof L.Polygon || layer instanceof L.Polyline) {
                layer.setStyle(stylelayer.defecto);
                hoveredSourceRef.current = '';
            }
        }, 5);

        layer.on('mouseover', onMouseOver);
        layer.on('mouseout', onMouseOut);
    };

    useEffect(() => {
        if (map) {
            map.setView(currentPosition || defaultMapCenter);
        }
    }, [map, currentPosition]);

    const isPointInArea = ({ lat, lng }: LatLngLiteral) => {
        const p = point([lng, lat]);
        return !!polygonFeature && booleanPointInPolygon(p, polygonFeature);
    };

    useEffect(() => {
        if (map) {
            map.on('click', (e: LeafletMouseEvent) => {
                const { lat, lng } = e.latlng;
                onManualPolygonChange([lng, lat]);
            });
        }

        return () => {
            map && map.off('click');
        };
    }, [map, onManualPolygonChange]);

    useEffect(() => {
        if (map) {
            const onHover = throttle((e: LeafletMouseEvent) => {
                setHoverInfo({
                    point: e.containerPoint,
                    isAvailable: isPointInArea(e.latlng),
                });
            }, 5);

            map.on('mousemove', onHover);
        }

        return () => {
            map && map.off('mousemove');
        };
    }, [map]);

    useEffect(() => {
        let historyLayers: any;
        if (map && addedShapesHistory.length > 0) {
            historyLayers = addedShapesHistory.map(({ geoJson }) => {
                return L.geoJSON(geoJson, {
                    style: {
                        fillColor: '#f94f4b',
                        color: '#f94f4b',
                    },
                }).addTo(map);
            });
        }

        return () => {
            if (map && historyLayers) {
                historyLayers.forEach((layer: any) => {
                    map.removeLayer(layer);
                });
            }
        };
    }, [map, addedShapesHistory]);

    useEffect(() => {
        let shapeLayer: any;
        let marker: Marker;

        if (map && selectedGeoJsonShape) {
            const drawTargetAreaMarker = (map: Map, targetPosition: LatLngExpression) => {
                const myIcon = L.icon({
                    iconUrl: 'https://mapnav-assets.s3.eu-central-1.amazonaws.com/marker.png',
                    iconSize: [60, 60],
                    iconAnchor: [30, 60],
                });

                return L.marker(targetPosition, {
                    icon: myIcon,
                }).addTo(map);
            };

            if (selectedGeoJsonShape && selectedGeoJsonShape.type === 'Point') {
                // Handle Point geometry
                const [lng, lat] = selectedGeoJsonShape.coordinates;
                shapeLayer = drawTargetAreaMarker(map, [lat, lng]);
            } else if (selectedGeoJsonShape && selectedGeoJsonShape.type === 'Polygon') {
                // Handle Polygon geometry
                shapeLayer = L.geoJSON(selectedGeoJsonShape, {
                    style: {
                        fillColor: '#f94f4b',
                        color: '#f94f4b',
                    },
                }).addTo(map);

                // Place a marker at the first coordinate of the first LinearRing
                const firstCoordinate = selectedGeoJsonShape.coordinates[0][0];
                marker = drawTargetAreaMarker(map, [firstCoordinate[1], firstCoordinate[0]]);
            } else if (selectedGeoJsonShape && selectedGeoJsonShape.type === 'LineString') {
                // Handle LineString geometry
                shapeLayer = L.geoJSON(selectedGeoJsonShape, {
                    style: {
                        color: '#f94f4b',
                        weight: 3,
                    },
                }).addTo(map);

                // Place a marker at the starting point of the LineString
                const firstCoordinate = selectedGeoJsonShape.coordinates[0];
                marker = drawTargetAreaMarker(map, [firstCoordinate[1], firstCoordinate[0]]);
            } else if (selectedGeoJsonShape && selectedGeoJsonShape.type === 'MultiPolygon') {
                // Render other GeoJSON shapes (fallback)
                shapeLayer = L.geoJSON(selectedGeoJsonShape, {
                    style: {
                        fillColor: '#f94f4b',
                        color: '#f94f4b',
                    },
                }).addTo(map);
                const firstCoordinate = selectedGeoJsonShape.coordinates[0][0][0];

                marker = drawTargetAreaMarker(map, [firstCoordinate[1], firstCoordinate[0]]);
            } else if (selectedGeoJsonShape) {
                console.log(`Shape type is not supported ${selectedGeoJsonShape}`);
            }
        }

        return () => {
            if (map && shapeLayer) {
                map.removeLayer(shapeLayer);
                marker?.removeFrom(map);
            }
        };
    }, [map, selectedGeoJsonShape]);

    useEffect(() => {
        mapRef.current!.addEventListener('mouseout', () => {
            setHoverInfo(null);
        });
    }, []);

    const clearMapLayers = () => {
        if (map) {
            map.eachLayer((layer: L.Layer) => {
                if (!(layer instanceof L.TileLayer)) {
                    map.removeLayer(layer);
                }
            });
        }
    };

    return (
        <Box
            sx={{
                height: '100%',
            }}
            className="flex-grow flex w-full relative"
            ref={mapRef}
        >
            <MapTooltip hoverInfo={hoverInfo} />
            <MapInfo sourceName={hoveredSourceRef.current} map={map} />
        </Box>
    );
};
