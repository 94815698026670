import React, { useEffect } from 'react';
import L from 'leaflet';

interface MapInfoProps {
    sourceName: string;
    map: L.Map | undefined;
}

const MapInfo: React.FC<MapInfoProps> = ({ sourceName, map }) => {
    useEffect(() => {
        if (map) {
            const infoControl = new L.Control({ position: 'bottomleft' });
            let infoDiv: HTMLElement | null = null;

            infoControl.onAdd = () => {
                if (!infoDiv) {
                    infoDiv = L.DomUtil.create(
                        'div',
                        'map-info p-2 text-sm leading-6 bg-white bg-opacity-80 shadow-lg rounded-md',
                    );
                }

                const source = sourceName.length > 0 ? sourceName : 'Unavailable';
                infoDiv.innerHTML = `Source: ${source}<br>`;

                return infoDiv;
            };

            infoControl.addTo(map);

            return () => {
                map.removeControl(infoControl);
            };
        }
    }, [sourceName, map]);

    return null;
};

export default MapInfo;
