import { GridCellParams } from '@mui/x-data-grid';
import { IconButton, Tooltip } from '@mui/material';
import * as React from 'react';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { OrganizationForm } from '../../interfaces';

interface OrganizationActionsLabelCellProps extends GridCellParams {
    setSelectedOrganization: (value: React.SetStateAction<OrganizationForm>) => void;
    setIsOrganizationDialogOpen: (value: boolean) => void;
}

export const OrganizationActionsLabelCell = ({
    row,
    setSelectedOrganization,
    setIsOrganizationDialogOpen,
}: OrganizationActionsLabelCellProps) => {
    const organization = row as OrganizationForm;

    const handleEditClick = () => {
        setSelectedOrganization(organization);
        setIsOrganizationDialogOpen(true);
    };

    const handleDeleteClick = () => {
        console.log(`Delete organization with ID: ${organization.id}`);
    };

    return (
        <>
            <span>
                <Tooltip title="Редагувати організацію" placement="top">
                    <IconButton size="small" edge="end" aria-label="edit" onClick={handleEditClick}>
                        <EditIcon />
                    </IconButton>
                </Tooltip>

                <Tooltip title="Видалити організацію" placement="top">
                    <IconButton size="small" edge="end" aria-label="delete" onClick={handleDeleteClick}>
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
            </span>
        </>
    );
};
