import { MultiPolygon, multiPolygon } from '@turf/turf';
import { useAppSelector } from '../store/hooks';
import { selectMapUploads } from '../store/maps.reducer';
import { mergeGeoJsonShapesIntoMultiPolygon } from '../utils/mergeGeoJsonShapesIntoMultiPolygon';
import { useMemo } from 'react';
import { Feature } from 'geojson';

export const useGetAvailableAreaPolygon = () => {
    let mapUploads = useAppSelector(selectMapUploads);

    return useMemo(() => {
        if (!mapUploads) {
            return null;
        }

        mapUploads = mapUploads.filter((upload) => upload.status === 'COMPLETED');

        if (mapUploads.length === 0) {
            return null;
        }

        const combinedShape = mergeGeoJsonShapesIntoMultiPolygon(mapUploads.map((upload) => upload.geoJson));
        return multiPolygon(combinedShape.coordinates);
    }, [mapUploads]);
};

export const useGetMergedAvailableAreaPolygon = () => {
    const mapUploads = useAppSelector(selectMapUploads);

    return useMemo(() => {
        if (!mapUploads) return null;

        const completedUploads = mapUploads.filter((upload) => upload.status === 'COMPLETED');

        if (completedUploads.length === 0) return null;

        const groupedBySource = completedUploads.reduce((acc: Record<string, any[]>, current) => {
            const { source, geoJson } = current;

            if (!acc[source]) {
                acc[source] = [];
            }

            acc[source].push(geoJson);
            return acc;
        }, {});

        const mergedShapes: Record<string, Feature<MultiPolygon>> = {};

        Object.keys(groupedBySource).forEach((source) => {
            const geoJsonShapes = groupedBySource[source];
            const mergedGeoJson = mergeGeoJsonShapesIntoMultiPolygon(geoJsonShapes);

            mergedShapes[source] = {
                type: 'Feature',
                geometry: mergedGeoJson,
                properties: {
                    name: source,
                },
            };
        });

        return mergedShapes;
    }, [mapUploads]);
};
