import { Field, Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    TextField,
    FormHelperText,
    FormLabel,
} from '@mui/material';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { loadOrganizations } from '../store/organizations.reducer';
import { loadSystemConfigurations, selectSystemConfigurations } from '../store/devices.reducer';
import ToastWrapper from './ToastWrapper';
import { useToastMessage } from '../hooks/useToastMessage';
import { SwatchesPicker } from 'react-color';
import { SystemConfiguration, ToastType } from '../interfaces';
import { upsertOrganization } from '../api/upsertOrganization';
import { objectIdToString } from '../utils/objectIdToString';
import { OrganizationForm, UpsertOrganizationDialogProps } from '../interfaces';
import { FormikAutocomplete } from './FormikAutocomplete';

export const UpsertOrganizationDialog = ({ open, onClose, initialValues }: UpsertOrganizationDialogProps) => {
    const dispatch = useAppDispatch();
    const oscarConfigurations = useAppSelector(selectSystemConfigurations);
    const [header, setHeader] = useState('Add');

    useEffect(() => {
        dispatch(loadSystemConfigurations());

        const headerValue = initialValues.id ? 'Update' : 'Add';
        setHeader(headerValue);
    }, []);

    const { isOpened, toastMessage, setToastMessage, handleCloseToast } = useToastMessage();

    const isOptionEqualToValue = (option: SystemConfiguration, value: SystemConfiguration | null) => {
        return option.systemVersion === value?.systemVersion;
    };

    const validateForm = (values: any) => {
        const errors: OrganizationForm = {};

        if (!values.name) {
            errors.name = 'Required';
        }

        if (!values.labelColor) {
            errors.labelColor = 'Required';
        }

        return errors;
    };

    const handleSubmit = async (values: OrganizationForm, { setSubmitting }: any) => {
        try {
            const oscarConfigurationId = values.oscarConfiguration
                ? objectIdToString(values.oscarConfiguration._id)
                : null;
            const id = values.id ? values.id : null;

            await upsertOrganization({
                id,
                name: values.name!,
                labelColor: values.labelColor!,
                oscarConfigurationId,
            });
            dispatch(loadOrganizations());
            onClose();
        } catch (error) {
            setToastMessage({
                message: `Під час створення конфігурації відбулася помилка`,
                type: ToastType.WARNING,
            });
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>{header} Organization</DialogTitle>
            <DialogContent sx={{ width: 370 }}>
                <Formik
                    validate={validateForm}
                    onSubmit={handleSubmit}
                    //Need to prevent MUI warnings. If there is no configuration API returns empty oscarConfiguration object
                    initialValues={{
                        ...initialValues,
                        oscarConfiguration: initialValues.oscarConfiguration?.systemVersion
                            ? initialValues.oscarConfiguration
                            : null,
                    }}
                >
                    {({ errors, touched, values, isSubmitting, setFieldValue }) => (
                        <Form>
                            <Box sx={{ marginTop: 1, marginBottom: 1 }}>
                                <FormControl fullWidth sx={{ marginBottom: 2 }}>
                                    <Field
                                        as={TextField}
                                        name="name"
                                        label="Organization Name *"
                                        variant="outlined"
                                        size="small"
                                        error={touched.name && !!errors.name}
                                        helperText={touched.name && errors.name}
                                    />
                                </FormControl>

                                {oscarConfigurations && (
                                    <FormControl fullWidth sx={{ marginBottom: 2 }}>
                                        <Field
                                            as={FormikAutocomplete}
                                            size="small"
                                            name="oscarConfiguration"
                                            label="Configuration"
                                            isOptionEqualToValue={isOptionEqualToValue}
                                            options={oscarConfigurations}
                                            getOptionLabel={(option: SystemConfiguration) =>
                                                option.systemVersion || ''
                                            }
                                            value={values.oscarConfiguration || null}
                                        />
                                    </FormControl>
                                )}

                                <Box sx={{ marginBottom: 2 }}>
                                    <div style={{ marginBottom: '10px' }}>
                                        <FormLabel
                                            className={`${
                                                touched.labelColor && !!errors.labelColor ? 'Mui-error' : ''
                                            }`}
                                        >
                                            Organization Label Color
                                        </FormLabel>
                                    </div>

                                    <SwatchesPicker
                                        color={values['labelColor']}
                                        onChange={(color) => {
                                            setFieldValue('labelColor', color.hex);
                                        }}
                                    />

                                    {touched.labelColor && !!errors.labelColor && (
                                        <FormHelperText
                                            style={{
                                                margin: '4px 14px 0',
                                            }}
                                            className={'Mui-error'}
                                        >
                                            {touched.labelColor && errors.labelColor}
                                        </FormHelperText>
                                    )}
                                </Box>

                                <DialogActions>
                                    <Button size="small" variant="outlined" onClick={onClose} color="error">
                                        Cancel
                                    </Button>
                                    <Button
                                        type="submit"
                                        size="small"
                                        variant="outlined"
                                        disabled={isSubmitting}
                                        color="primary"
                                    >
                                        {header}
                                        {isSubmitting && (
                                            <CircularProgress style={{ marginLeft: '5px' }} size={15} />
                                        )}
                                    </Button>
                                </DialogActions>
                            </Box>
                        </Form>
                    )}
                </Formik>
            </DialogContent>
            <ToastWrapper
                open={isOpened}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                autoHideDuration={3000}
                onClose={handleCloseToast}
                message={toastMessage?.message}
                type={toastMessage?.type}
            />
        </Dialog>
    );
};
